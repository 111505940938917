<template>
    <div ref="draggableContainer" id="draggableDiv" @mousedown="dragMouseDown">
        <v-card @keyup.esc="$emit('closeDialog')" class="commentContent">
            <v-row justify="space-between">
                <v-card-title>
                    <h2>Comments</h2>
                </v-card-title>
                <v-icon margin-right="2px" @click="$emit('closeDialog')">mdi-close</v-icon>
            </v-row>
            <v-divider></v-divider>
            <v-container>
                <v-layout v-for="comment in commentControlSettings.commentsForSeat" class="comments" :key="comment.id">
                    <v-flex >
                        <i>{{formatDate(comment.createdDate)}} </i> <b>{{comment.createdBy}}:</b> {{comment.commentData}}
                    </v-flex>
                    <v-flex shrink>
                        <v-btn 
                               v-if="currentUsernameShortened == comment.createdBy"
                               @click="deleteComment(comment)"
                               icon
                               :disabled="isLoading">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-container>
                <v-text-field @keyup.enter="addComment()"
                              autofocus
                              class="comments"
                              v-model="commentInput"
                              label="New Comment"
                              color="purple"
                              persistent-hint
                              filled
                              clearable></v-text-field>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="$emit('closeDialog')"
                       :disabled="isLoading">
                    Cancel
                </v-btn>
                <v-btn @click="addComment()"
                       color="primary"
                       :disabled="isLoading">
                    Comment
                </v-btn>

            </v-card-actions>
            <v-snackbar v-model="emptyCommentSnackbar"
                        absolute
                        bottom
                        color="error"
                        :timeout="snackbarTimeout">
                <span>Comment must not be empty</span>
                <v-icon right>mdi-cancel</v-icon>
            </v-snackbar>
        </v-card>
        <v-overlay :value="isLoading"
                   class="loadingOverlay">
            <v-progress-circular indeterminate
                                 size="64"
                                 color="purple"
            ></v-progress-circular>
        </v-overlay>

    </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from "vuex";

export default {
    name: 'CommentControls',
    props: ['commentControlSettings'],
    data: function () {
        return {
            commentInput: "",
            emptyCommentSnackbar: false,
            snackbarTimeout: 2000,
            isLoading: false,
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0
            }
        }
    },
    methods: {
        addComment: async function () {
            if (this.commentInput) {
                var commentInfo = {
                    commentText: this.commentInput,
                    selectedDesk: this.commentControlSettings.selectedDesk
                }
                this.$emit(`saveComment`, commentInfo)
            }
            else {
                this.emptyCommentSnackbar = true;
            }
            this.commentInput = "";
        },
        pressEnterToAddComment: function(e) {
            if (e.keyCode === 13) {
                this.addComment();
            } else if (e.keyCode === 27) {
                this.$emit('closeDialog')
            }    
        },
        deleteComment: async function (comment) {
            if (comment == null)
                alert("Comment Id is null");
            else
                await this.$emit('deleteComment', comment);
        },
        toggleLoading: function () {
            this.isLoading = !this.isLoading;
        },
        formatDate(rawDate) {
            return moment(rawDate).format('MM/DD/YYYY h:mm a')
        },
        dragMouseDown: function (event) {
            event.preventDefault()
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            document.onmousemove = this.elementDrag
            document.onmouseup = this.closeDragElement
        },
        elementDrag: function (event) {
            event.preventDefault()
            
            this.positions.movementX = this.positions.clientX - event.clientX
            this.positions.movementY = this.positions.clientY - event.clientY
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            // set the element's new position:
            if (this.$refs.draggableContainer.offsetTop < 0) {
                this.$refs.draggableContainer.style.top = 0;
            }
            else {
                this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
            }
            if (this.$refs.draggableContainer.offsetLeft < 0) {
                this.$refs.draggableContainer.style.left = 0;
            }
            else {
                this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
            }
        },
        closeDragElement() {
            document.onmouseup = null
            document.onmousemove = null
        },
    },
    computed: {
        ...mapGetters(["user"]),
        currentUsernameShortened: function() {
            let user = this.user.name.split(' ')
            let lastInitial = user[1].charAt(0)
            lastInitial = lastInitial + '.'

            return user[0] + ' ' + lastInitial
        }
    }
}
</script>

<style>
    .comments {
        position: relative;
        text-align: left;
        z-index: 100;
        padding: 15px;
    }
    .loadingOverlay {
        position: absolute;
    }
    #draggableDiv {
        z-index: 100;
        position: absolute;
        min-width: 500px;
        max-width: 500px;
        cursor: move;
    }
    .commentContent {
        padding: 0em 1em;
    }

    #draggableDiv > div.commentContent.v-card.v-sheet.theme--light > div.row.justify-space-between > button {
        padding-right: 12px;
    }
</style>