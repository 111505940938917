<template>
    <v-dialog v-model="showDialog"
              :persistent="true"
              max-width="25%">
        <template v-slot:activator="{ }">
            <slot name="activator"></slot>
        </template>
        <v-card>
            <v-snackbar
                v-model="snackbar"
                absolute
                top
                color="error"
                :timeout="snackbarTimeout"
            >
                <span>Name must be unique and not empty!</span>
                <v-icon right>mdi-cancel</v-icon>
            </v-snackbar>
            <v-card-title>
                {{txtDialogOptions.Title}}
            </v-card-title>
            <v-card-text>
                {{txtDialogOptions.MainText}} {{txtDialogOptions.SeatingChartName}}
            </v-card-text>
            <v-divider></v-divider>

            <v-text-field
                class="textInput"
                color="purple"
                v-model="txtInput"
                :label="txtDialogOptions.InputLabel"
                clearable
            ></v-text-field>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn @click="$emit(txtDialogOptions.CancelEvent)">
                    {{txtDialogOptions.CancelText}}
                </v-btn>
                <v-btn @click="confirmBtnClicked()"
                       color="primary">
                    {{txtDialogOptions.ConfirmText}}
                </v-btn>
            </v-card-actions>
        </v-card>


    </v-dialog>
</template>


<script>
    import store from "@/store";
    export default {
        name: 'TextInputDialog',
        props: ['txtDialogOptions', 'showDialog'],
        data() {
            return {
                txtInput: "",
                snackbar: false,
                snackbarTimeout: 2000,
            }
        },
        methods: {
            confirmBtnClicked: async function () {
                if (this.txtInput || /[a-zA-Z]/.test(this.txtInput)) {
                    let floorplans = store.getters.floorplansList;
                    let isUnique = true;
                    let input = this.txtInput.replace(/\s+/g, ' ').trim();
                    this.txtInput = input;

                    floorplans.forEach(element => {
                        if (element.scenarioName == input) {
                            isUnique = false;
                            this.snackbar = true;
                        }
                    });
                    if (isUnique) {
                        this.$emit(`${this.txtDialogOptions.ConfirmEvent}`, `${input}`);
                    }
                }
                else {
                    this.snackbar = true;
                }
            },
        },
        watch: {
            showDialog(visible) {
                if (visible && this.txtDialogOptions.SeatingChartName != "") {
                    this.txtInput = this.txtDialogOptions.SeatingChartName;
                }
                else {
                    this.txtInput = "";
                }
            }
        }
    }
</script>

<style>
    .textInput {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
    }
</style>