<template>
  <div v-if="currentFloorplan">
    <div class="d-flex justify-start">
      <v-card
        v-on:drop="drop"
        v-on:dragover="allowDrop"
        class="unassignedNamesList"
        id="unassignedNamesSidebar"
      >
        <p
          v-for="name in unassignedNames"
          :key="name.id"
          draggable="true"
          v-on:dragstart="dragStart"
          v-on:drag="dragging"
          class="deskLabel"
          :id="name.id"
        >
          {{ name.fullName }}
        </p>
      </v-card>
      <div class="d-flex flex-column">
      <v-card class="SF-Office">
        <h1 class="chartTitle">Sioux Falls Office</h1>
        <main class="wrapper">
          <div
            v-for="desk in deskLayout.filter(seat => seat.office === 'SF')"
            :key="desk.id"
            :class="[seatHasComment(desk.id) ? 'deskWithComments':'desk', selectedDesk == desk.id ? 'viewingSeatComments':'']"
            :id="`desk-${desk.id}`"
            v-on:drop="drop"
            v-on:dragover="allowDrop"
            @click="openCommentControls(desk.id)"
            :style="`left: ${desk.xCoordinate}px; top: ${desk.yCoordinate}px; width: ${desk.width}px; height: ${desk.height}px `"
          >
            <p
              v-if="getDeskPlacementType(desk.id) == 1"
              draggable="true"
              v-on:dragstart="dragStart"
              v-on:drag="dragging"
              class="deskLabel"
              :id="getDeskLabel(desk.id)"
            >
              {{ employeeName(getDeskLabel(desk.id)) }}
            </p>
            <p
              v-if="getDeskPlacementType(desk.id) == 3"
              draggable="false"
              class="deskLabel"
              :id="'Meeting'"
            >
              {{ desk.podLocation }}
            </p>
            <p
              v-if="getDeskPlacementType(desk.id) == 2"
              draggable="true"
              v-on:dragstart="dragStart"
              v-on:drag="dragging"
              class="deskLabel"
              :id="getDeskLabel(desk.id)"
            >
              {{ employeeName(getDeskLabel(desk.id)) }}
            </p>
            <p
              v-if="getDeskPlacementType(desk.id) == 4"
              draggable="false"
              class="deskLabel"
              :id="'Office'"
            >
              {{ desk.podLocation }}
            </p>
            <p
              v-if="getDeskPlacementType(desk.id) == 5"
              draggable="false"
              class="deskLabel"
              :id="'FrontDesk'"
            >
              {{ "Front Desk" }}
            </p>
          </div>
          <SeatCommentControls  
            v-if="showCommentControls" 
            :comment-control-settings="commentControlSettings" 
            ref="commentControls"
            @saveComment="saveNewComment($event)" 
            @deleteComment="deleteComment($event)" 
            @closeDialog="closeCommentControls()">
          </SeatCommentControls>
        </main>
      </v-card>
      <v-card class="RC-Office">
        <h1 class="chartTitle">Rapid City Office</h1>
        <main class="wrapper">
          <div
            v-for="desk in deskLayout.filter(seat => seat.office === 'RC')"
            :key="desk.id"
            :class="[seatHasComment(desk.id) ? 'deskWithComments':'desk', selectedDesk == desk.id ? 'viewingSeatComments':'']"
            :id="`desk-${desk.id}`"
            v-on:drop="drop"
            v-on:dragover="allowDrop"
            @click="openCommentControls(desk.id)"
            :style="`left: ${desk.xCoordinate}px; top: ${desk.yCoordinate}px; width: ${desk.width}px; height: ${desk.height}px `"
          >
            <p
              v-if="getDeskPlacementType(desk.id) == 1"
              draggable="true"
              v-on:dragstart="dragStart"
              v-on:drag="dragging"
              class="deskLabel"
              :id="getDeskLabel(desk.id)"
            >
              {{ employeeName(getDeskLabel(desk.id)) }}
            </p>
            <p
              v-if="getDeskPlacementType(desk.id) == 3"
              draggable="false"
              class="deskLabel"
              :id="'Meeting'"
            >
              {{ desk.podLocation }}
            </p>
            <p
              v-if="getDeskPlacementType(desk.id) == 2"
              draggable="true"
              v-on:dragstart="dragStart"
              v-on:drag="dragging"
              class="deskLabel"
              :id="getDeskLabel(desk.id)"
            >
              {{ employeeName(getDeskLabel(desk.id)) }}
            </p>
            <p
              v-if="getDeskPlacementType(desk.id) == 4"
              draggable="false"
              class="deskLabel"
              :id="'Office'"
            >
              {{ desk.podLocation }}
            </p>
            <p
              v-if="getDeskPlacementType(desk.id) == 5"
              draggable="false"
              class="deskLabel"
              :id="'FrontDesk'"
            >
              {{ "Front Desk" }}
            </p>
          </div>
          <SeatCommentControls  
            v-if="showCommentControls" 
            :comment-control-settings="commentControlSettings" 
            ref="commentControls"
            @saveComment="saveNewComment($event)" 
            @deleteComment="deleteComment($event)" 
            @closeDialog="closeCommentControls()">
          </SeatCommentControls>
        </main>
      </v-card>
    </div>
    </div>
  </div>
</template>

<script>
import seatingChartDataAccess from "../../../dataAccess/seatingChartDataAccess"
import SeatCommentControls from "../components/seatCommentControls"
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
      SeatCommentControls 
  },
  data: function () {
    return {
      deskLayout: [],
      employees: [],
      comments: [],
      seatingChartDAO: new seatingChartDataAccess(),
      showCalendarDialog: false,
      showCommentControls: false,
      commentsForSeat: [],
      commentControlSettings: {},
      selectedDesk: null,
    };
  },
  computed: {
    ...mapGetters(["currentFloorplan", "floorplanSeatsList", "user"]),
    unassignedNames() {
      return this.employees.filter(
        (e) => !this.selectedFloorplanSeatAssignments.find((d) => d.employeeId === e.id)
      );
    },
    selectedFloorplanSeatAssignments() {
      return this.floorplanSeatsList.filter(
        (e) => e.floorplanId === this.currentFloorplan.id
      );
    },
    selectedFloorplanComments() {
      return this.comments.filter(
        (e) => e.floorplanId === this.currentFloorplan.id
      );
    },
  },
  methods: {
    openCommentControls(deskId) {
          this.selectedDesk = deskId;
        this.commentControlSettings = {
          commentsForSeat: this.filterCommentsForSeat(deskId),
          selectedDesk: deskId,
        }
        this.showCommentControls = true;
    },
    closeCommentControls() {
        this.selectedDesk = null;
        this.showCommentControls = false;
    },
    saveNewComment: async function (newCommentInfo) {
        this.$refs.commentControls.toggleLoading();
        let commentObject = {
            seatId: newCommentInfo.selectedDesk,
            floorplanId: this.currentFloorplan.id,
            commentData: newCommentInfo.commentText,
            createdBy: this.getShortenedUserName(),
            createdDate: new Date().toLocaleString(),
        };
        await this.seatingChartDAO.saveNewComment(commentObject);
        this.comments = await this.seatingChartDAO.getAllComments();
        this.commentControlSettings.commentsForSeat = this.filterCommentsForSeat(newCommentInfo.selectedDesk);
        this.$refs.commentControls.toggleLoading();
      },
      deleteComment: async function (comment) {
        this.$refs.commentControls.toggleLoading();
        await this.seatingChartDAO.deleteComment(comment);
        await this.refreshCommentList(comment.seatId);
        this.$refs.commentControls.toggleLoading();

    },
    refreshCommentList: async function (seatId) {
        this.comments = await this.seatingChartDAO.getAllComments();
        this.commentControlSettings.commentsForSeat = this.filterCommentsForSeat(seatId);
    },
    filterCommentsForSeat: function(deskId) {
        var floorplanId = this.currentFloorplan.id;
        var currentFloorplanComments = this.comments.filter(function (comment) { return comment.floorplanId == floorplanId })
        var seatComments = currentFloorplanComments.filter(function (comment) { return comment.seatId == deskId })
        return seatComments;
    },
    employeeName(employeeId) {
      if (employeeId === null) {
        return "";
      } else if (
        this.employees.find((e) => e.id === employeeId) === undefined
      ) {
        return "Drop In";
      } else {
        return this.employees.find((e) => e.id === employeeId).fullName;
      }
    },
    getDeskLabel(deskId) {
      let employee = this.selectedFloorplanSeatAssignments.find((e) => e.seatDetailsId === deskId)
      if(employee === undefined)
        return "NULL"
      else
        return employee.employeeId;
    },
    getDeskPlacementType(deskId) {
      let employee = this.selectedFloorplanSeatAssignments.find((e) => e.seatDetailsId === deskId)
      if(employee === undefined)
        return "NULL"
      else
        return employee.deskTypeId;
    },
    dragStart: function (event) { //Grabs needed data for data swap
      event.dataTransfer.setData("UserId", event.target.id);
      event.dataTransfer.setData("ParentId", event.target.parentNode.id);
    },
    dragging: function (event) {
      event; /* Does nothing right now */
    },
    allowDrop: function (event) {
      event.preventDefault();
    },
    deleteFloorplan: async function () {
      if (this.selectedFloorplan == null)
        alert("A floorplan must be selected using the dropdown.");
      else {
        await this.seatingChartDAO.deleteLoadedFloorplan(this.deskLayout);
        //After deletion, load the published floorplan?
      }
    },
    findSeatAssignment(seatId) {
        let assignment = this.floorplanSeatsList.find((assignment) => 
        "desk-"+assignment.seatDetailsId === seatId 
        && assignment.floorplanId === this.currentFloorplan.id);
        if(assignment === undefined) //If the assignment doesn't exist
        {
          let newAssignment = {};
          newAssignment.deskTypeId = 1;
          newAssignment.employeeId = null;
          newAssignment.floorplanId = this.currentFloorplan.id;
          newAssignment.id = 0;
          let parsedSeatId = seatId.replace('desk-', ''); //removes desk- in front of Id
          parsedSeatId = parseInt(parsedSeatId); //converts string to int
          newAssignment.seatDetailsId = parsedSeatId;
          let newLength = this.floorplanSeatsList.push(newAssignment);
          //Returns newly added object
          return this.floorplanSeatsList[newLength - 1];
        }
        else {
          return assignment;
        }
    },
    seatHasComment(seatId) {
      var comment = this.selectedFloorplanComments.find(
        (c) => c.seatId === seatId
      );
      if (comment === undefined)
        return false;
      else
        return true;
    },
    drop: function (event) {
      if(this.currentFloorplan === null) {
        alert("Please select or add a new seating chart to edit.")
        return;
      }
      if(this.currentFloorplan.stateId === 3 || this.currentFloorplan.stateId === 4 || this.currentFloorplan.stateId === 5) {
        alert("You cannot edit an approved, published, or archived seating chart.")
        //Add this in a snackbar later at the bottom of the screen.
        return;
      }
      event.preventDefault();
      let dragUserId = event.dataTransfer.getData("UserId");
      let dragParentId = event.dataTransfer.getData("ParentId");
      if(dragUserId === event.target.id || dragParentId === event.target.parentNode.id) {
        return;
      }
      //To populated desk from another desk when dropped on name
      else if ((event.target.parentNode.className === "desk" || event.target.parentNode.className === "deskWithComments") && event.target.className === "deskLabel") {
        if(event.target.draggable === false && event.target.innerText !== "Drop In") //Disallow drop on non-draggable
        {
          return;
        }
        else if(event.target.innerText === "Drop In")
        {
          let newAssignment = this.findSeatAssignment(event.target.parentElement.id);
          let oldAssignment = this.findSeatAssignment(dragParentId);
          newAssignment.employeeId = dragUserId;
          newAssignment.deskTypeId = 1;
          oldAssignment.employeeId = null;
          return;
        }
        else if(dragParentId === "unassignedNamesSidebar") {
          let newAssignment = this.findSeatAssignment(event.target.parentElement.id);
          newAssignment.employeeId = dragUserId;
          return;
        } 

        let newAssignment = this.findSeatAssignment(event.target.parentElement.id);
        let oldAssignment = this.findSeatAssignment(dragParentId);
        newAssignment.employeeId = dragUserId;
        oldAssignment.employeeId = event.target.id;

      }
      //To empty desk from another desk
      else if ((event.target.className === "desk" || event.target.className === "deskWithComments") && event.target.className != "deskLabel") {
        var newAssignment = this.findSeatAssignment(event.target.id);
        newAssignment.employeeId = dragUserId;
        //Only do if NOT dragging from unassigned sidebar
        if(dragParentId !== "unassignedNamesSidebar") {
          let oldAssignment = this.findSeatAssignment(dragParentId);
          oldAssignment.employeeId = null;
        }
      }
      //From Desk to Unassigned Names on a name
      else if(event.target.parentNode.id === "unassignedNamesSidebar") {
        let oldAssignment = this.findSeatAssignment(dragParentId);
        oldAssignment.employeeId = null;
      }
      //From Desk to Unassigned Names 
      else if(event.target.id === "unassignedNamesSidebar") {
        let oldAssignment = this.findSeatAssignment(dragParentId);
        oldAssignment.employeeId = null;
      }
      },
    getShortenedUserName: function () {
        var userName = this.user.name;
        var indexAfterLastInitial = userName.indexOf(' ') + 2;
        var firstNameLastInitial = userName.substr(0, indexAfterLastInitial);
        firstNameLastInitial = firstNameLastInitial + '.'
        return firstNameLastInitial;
    },
  },
  mounted: async function () {
    this.deskLayout = await this.seatingChartDAO.getSeatingLayout();
    this.employees = await this.seatingChartDAO.getAllUsers();
    this.comments = await this.seatingChartDAO.getAllComments();
    this.selectedFloorplan = null;
    if (this.currentFloorplan != null) {
      this.seatAssignments = await this.seatingChartDAO.getSeatAssignments(
        this.currentFloorplan.id
      );
      this.comments = await this.seatingChartDAO.getAllComments();
    }
  },
};
</script>

<style>


html,
body {
  height: 100%;
  font-family: "Roboto";
}

</style>
<style scoped>
.v-card {
  margin: 1em;
}
.chartTitle {
  text-align: left;
  padding-left: 0.5em;
  margin-bottom: -0.5em;
}

.wrapper {
  position: relative;
  width: 1032px;
  height: 1165px;
}
.deskLabel {
  vertical-align: sub;
  margin-bottom: unset;
  padding: 2px 4px;
}

.desk {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
  outline: solid;
}

.deskWithComments {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #a593c9;
  outline: solid;
}

.viewingSeatComments{
    outline: solid #a593c9;
    z-index: 1;
}

.unassignedNamesList {
  position: sticky;
  top: 1em;
  height: 671px;
  width: 200px;
  padding: 15px;
  margin-bottom: 20px;
  overflow-y: auto;
}
</style>


