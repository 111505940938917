<template>
    <div style="height: 100px">
        <div class="title-bar">
            <h1>Manage Seating Chart</h1>
        </div>
        <div class="controls-container">
            <v-card>
                <ManageSeatingControls @toggleLoading="toggleLoadingOverlay()" />
            </v-card>
        </div>
        <v-overlay :value="isLoadingActive">
            <v-progress-circular indeterminate
                                    size="64"
                                    color="purple"
            ></v-progress-circular>
        </v-overlay>
        <ManageDandDLayout />
    </div>
</template>

<script>
    import ManageSeatingControls from "./components/ManageSeatingControls.vue";
    import ManageDandDLayout from "./components/ManageDandDLayout.vue";
    import navbarDisplayDataAccess from "../../dataAccess/navbarDisplayDataAccess";

export default {
    name: "manageseatingchart",
    data() {
        return {
            isLoadingActive: false,
            navbarDisplayDAO: new navbarDisplayDataAccess(),
        }
    },
    components: {
      ManageSeatingControls,
      ManageDandDLayout 
    },
    methods: {
        toggleLoadingOverlay: async function () {
            this.isLoadingActive = !this.isLoadingActive;
        },
    },
    mounted: function () {
        this.navbarDisplayDAO.activateMiniNavbarDisplay();
    },
    activated: function () {
        this.navbarDisplayDAO.activateMiniNavbarDisplay();
        }
};
</script>

<style scoped>
    .seatingchart-container {
        text-align: left;
    }
    .loadingCircle {
        margin-top: 25%;
    }
</style>