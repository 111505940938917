<template>
    <v-dialog v-model="showDialog"
              :persistent="true"
              max-width="20%">
        <template v-slot:activator="{ }">
            <slot name="activator"></slot>
        </template>
        <v-card>
            <v-card-title>
                {{dialogOptions.Title}}
            </v-card-title>
            <v-card-text>
                {{dialogOptions.MainText}}
            </v-card-text>
            <v-card-text>
                {{dialogOptions.SeatingChartName}}
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="$emit(dialogOptions.CancelEvent)">
                    {{dialogOptions.CancelText}}
                </v-btn>
                <v-btn @click="$emit(dialogOptions.ConfirmEvent)"
                       color="primary">
                    {{dialogOptions.ConfirmText}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
    export default {
        name: 'YesNoDialog',
        props: ['dialogOptions', 'showDialog']
    }


</script>