import store from "@/store"

class navbarDisplayDataAccess {
    activateMiniNavbarDisplay() {
        store.commit("activateMiniNavbar")
        return store.getters.navbarDisplayState;
    }
    getMiniDisplayValue() {
        return store.getters.navbarDisplayState;
    }
    setMiniDisplayValue(value) {
        store.commit("setDisplayState", value);
    }
}

export default navbarDisplayDataAccess;