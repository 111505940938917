import apiWrapper from "@/apiWrapper";
import store from "@/store";

class seatingChartDataAccess {
    static cacheTimeoutMilliseconds = 1000 * 60 * 5;

    async getSeatAssignments(floorplanId) {
        let response = await apiWrapper.getSeatAssignments(floorplanId);
        store.commit("updateFloorplanSeatsList", response.data);

        return store.getters.floorplanSeatsList;
    }

    async getAllComments() {
        let response = await apiWrapper.getAllComments();
        store.commit("updateCommentList", response.data);

        return store.getters.commentList;
    }

    async deleteComment(comment) {
        await apiWrapper.deleteComment(comment);
    }

    async saveNewComment(commentInfo) {
        await apiWrapper.saveNewComment(commentInfo);
    }
    
    async getAllUsers() {
        let response = await apiWrapper.getSeatingUsers();
        store.commit("updateEmployeeList", response.data);

        return store.getters.employeeList;
    }

    async getSeatingLayout() {
        let response = await apiWrapper.getSeatLayout();
        store.commit("updateSeatDetailsList", response.data);
        
        return store.getters.seatDetailsList;
    }

    async getListOfFloorplans() {
        let response = await apiWrapper.getAllFloorplans();
        store.commit("updateFloorplansList", response.data);

        return store.getters.floorplansList;
    }

    async getCurrentFloorplan() {
        let response = await apiWrapper.getCurrentFloorplan();
    
        return response.data;
    }

    async createFloorplan(floorplan) {
        let response = await apiWrapper.createFloorplan(floorplan);
        return response.data;
    }

    async renameFloorplan(floorplan) {
        await apiWrapper.renameFloorplanById(floorplan);
    }

    async deleteLoadedFloorplan(floorplanId) {
        await apiWrapper.deleteFloorplanById(floorplanId);
        await this.getListOfFloorplans();
    }

    async saveLoadedFloorplan(floorplan, deskLayout) {
        await apiWrapper.saveFloorplanByIdAPI(floorplan);
        let updateSeats = []
        let insertSeats = []
        let finalUpdatedSeats = []

        deskLayout.forEach(async (element) => {
            if(element.id == 0){
                insertSeats.push(element);
            }
            else{
                updateSeats.push(element);
            }
        });
        
        let response = await apiWrapper.saveFlooplanSeatsAPI(updateSeats);
        finalUpdatedSeats.push.apply(finalUpdatedSeats, response.data);
        response = await apiWrapper.insertNewFloorplanSeatsAPI(insertSeats);
        finalUpdatedSeats.push.apply(finalUpdatedSeats, response.data);

        store.commit("updateFloorplanSeatsList", finalUpdatedSeats);
        await this.getListOfFloorplans();
    }

    async approveFloorplan(floorplan) {
        await apiWrapper.updateFloorplanApproval(floorplan);
    }

    async requestFloorplanApproval(floorplan) {
        await apiWrapper.updateFloorplanApprovalRequest(floorplan);
    }

    async publishFloorplan(floorplan) {
        await apiWrapper.updatePublishedFloorplan(floorplan);
    }

    async getFloorplanApprovals(floorplanId) {
        return await apiWrapper.getFloorplanApprovals(floorplanId);
    }

    async addFloorplanApproval(floorplanId, userId) {
        return await apiWrapper.addFloorplanApproval(floorplanId, userId);
    }

    async deleteFloorplanApproval(floorplanId, userId) {
        return await apiWrapper.deleteFloorplanApproval(floorplanId, userId);
    }

    async deleteAllFloorplanApprovals(floorplanId) {
        return await apiWrapper.deleteAllFloorplanApprovals(floorplanId);
    }
}

export default seatingChartDataAccess;