<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col class="controlBtns" :cols="7">
                    <v-row>
                        <ConfirmationDialog :show-dialog="showSaveDialog" :dialog-options="dialogSettings"
                                            @saveChart="saveFloorplan()" @closeDialog="showSaveDialog = false">
                            <template v-slot:activator>
                                <v-btn class="Savebtn ctrlBtn white--text"
                                        color="primary"
                                        :loading="loadingSave"
                                        @click="openSaveDialog()"
                                        :disabled="!selectedFloorplan || selectedFloorplan.stateId >= 3">
                                    Save Floorplan
                                </v-btn>
                            </template>
                        </ConfirmationDialog>
                        <ConfirmationDialog :show-dialog="showDeleteDialog" :dialogOptions="dialogSettings"
                                            @deleteChart="deleteFloorplan()" @closeDialog="showDeleteDialog = false">
                            <template v-slot:activator>
                                <v-btn class="deleteBtn ctrlBtn white--text"
                                        color="red"
                                        :loading="loadingDelete"
                                        @click="openDeleteDialog()"
                                        :disabled="!selectedFloorplan || selectedFloorplan.stateId == 4">
                                    Delete
                                </v-btn>
                            </template>
                        </ConfirmationDialog>
                        <ConfirmationDialog :show-dialog="showRequestApprovalDialog" :dialog-options="dialogSettings"
                                            @requestApproval="requestFloorplanApproval()" @closeDialog="showRequestApprovalDialog = false">
                            <template v-slot:activator>
                                <v-btn class="requestApprovalBtn ctrlBtn white--text"
                                        color="#482484"
                                        :loading="loadingRequestApproval"
                                        @click="openRequestApprovalDialog()"
                                        :disabled="!selectedFloorplan || selectedFloorplan.stateId != 1">
                                    Request Approval
                                </v-btn>
                            </template>
                        </ConfirmationDialog>
                        <ConfirmationDialog :show-dialog="showApprovalDialog" :dialog-options="dialogSettings"
                                            @approveFloorplan="approveSelectedFloorplan()" @closeDialog="showApprovalDialog = false">
                            <template v-slot:activator>
                                <v-btn  v-if="!userHasApproved"
                                        class="approvalBtn ctrlBtn white--text"
                                        color="purple"
                                        :loading="loadingApprove"
                                        @click="openApprovalDialog()"
                                        :disabled="!selectedFloorplan || selectedFloorplan.stateId != 2 || !userIsApprover">
                                    Approve
                                </v-btn>
                                <v-btn  v-else
                                        class="approvalBtn ctrlBtn white--text"
                                        color="purple"
                                        :loading="loadingApprove"
                                        @click="unapproveSelectedFloorplan()"
                                        :disabled="!selectedFloorplan || selectedFloorplan.stateId != 2 || !userIsApprover">
                                    Unapprove
                                </v-btn>
                            </template>
                        </ConfirmationDialog>
                        <ConfirmationDialog :show-dialog="showPublishDialog" :dialog-options="dialogSettings"
                                            @publishFloorplan="publishSelectedFloorplan()" @closeDialog="showPublishDialog = false">
                            <template v-slot:activator>
                                <v-btn class="publishBtn ctrlBtn white--text"
                                        color="green"
                                        :loading="loadingPublish"
                                        @click="openPublishDialog()"
                                        :disabled="!selectedFloorplan || (selectedFloorplan.stateId != 3 && selectedFloorplan.stateId != 5)">
                                    Publish
                                </v-btn>
                            </template>
                        </ConfirmationDialog>
                    </v-row>
                    <v-row class="floorplanSelection">
                        <v-col :cols="8">
                            <v-select class="floorplanDropdown" :value="selectedFloorplan" :items="sortedFloorplanDropdown" item-value="id" single-line
                                      label="Selected Seating Chart" @input="setSelectedFloorplan" placeholder="Select A Seating Chart">
                                <template slot="selection">
                                    {{selectedChartDisplayName}}
                                </template>
                                <template slot="item" slot-scope="data">
                                    {{setSeatingChartDisplayName(data.item)}}
                                </template>
                            </v-select>
                        </v-col>
                            <TxtInputDialog :show-dialog="showNamingDialog" :txt-dialog-options="dialogSettings"
                                            @renameChart="renameFloorplan($event)" @closeDialog="showNamingDialog = false">
                                <template v-slot:activator>
                                    <v-btn class="renameBtn ctrlBtn mt-6"
                                           color="primary"
                                           dark
                                           @click="openRenameDialog()"
                                           :disabled="!selectedFloorplan">
                                        Rename
                                    </v-btn>
                                </template>
                            </TxtInputDialog>
                
                            <TxtInputDialog :show-dialog="showCreationDialog" :txt-dialog-options="dialogSettings"
                                            @createChart="createFloorplan($event)" @closeDialog="showCreationDialog = false">
                                <template v-slot:activator>
                                    <v-btn class="creationBtn ctrlBtn mt-6"
                                           color="primary"
                                           dark
                                           @click="openCreationDialog()">
                                        Add
                                    </v-btn>
                                </template>
                            </TxtInputDialog>
                       
                    </v-row>
                </v-col>
                <v-col v-if="selectedFloorplan" class="d-flex historicLabels">
                    <div class="d-flex flex-column text-left">
                        <label>Created By: {{selectedFloorplan.createdBy}}</label>
                        <label>Created Date: {{shortenedDate(selectedFloorplan.createdDate)}}</label>
                        <label>Modified Date: {{shortenedDate(selectedFloorplan.modifiedDate)}}</label>
                    </div>
                    <div class="d-flex flex-column text-left pl-4">
                        <label v-if="selectedFloorplan.stateId == 2">Approvals:</label>
                        <div v-if="selectedFloorplan.stateId == 2">
                            <label v-for="app in currentFloorplanApprovals" :key="app.user.email">
                                <v-icon color="green" v-if="app.approvalDate != null">check_circle</v-icon>
                                <v-icon color="red" v-else>cancel</v-icon>
                                {{getShortenedUserName(app.user.name)}}
                                </label>
                        </div>
                        <label v-if="selectedFloorplan.stateId >= 3">Approval Date: {{shortenedDate(selectedFloorplan.approvalDate)}}</label>
                        <label v-if="selectedFloorplan.stateId >= 4">Published By: {{selectedFloorplan.publishedBy}}</label>
                        <label v-if="selectedFloorplan.stateId >= 4">Published Date: {{shortenedDate(selectedFloorplan.publishedDate)}}</label>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-alert :value="alert_nullSelectedFloorplan" dismissible type="warning">You must select a seating chart to do that.</v-alert>
        <v-alert :value="alert_EmptyFloorplanSeats" dismissible type="warning">Floorplan has no seats set to save. Save Aborted.</v-alert>
        <v-alert :value="alert_floorplanIsPublished" dismissible type="warning">Cannot perform that action on a published Seating Chart.</v-alert>
        <v-alert :value="alert_floorplanIsApproved" dismissible type="warning">Cannot perform that action on a Seating Chart that is already approved.</v-alert>
        <v-alert :value="alert_floorplanIsNotApproved" dismissible typeof="warning">The selected Seating Chart must be approved to publish it.</v-alert>
        <v-alert :value="alert_floorplanApprovalNotRequested" dismissible type="warning">Approval must be requested before you can approve the Seating Chart.</v-alert>
    </div>
</template>


<script>
import seatingChartDataAccess from "../../../dataAccess/seatingChartDataAccess"
import ConfirmationDialog from "../components/confirmActionsDialog"
import TxtInputDialog from "../components/chartNameInputDialog"
import { mapGetters, mapMutations } from 'vuex'
import store from "@/store";

export default {
    data() {
        return {
            seatingChartDAO: new seatingChartDataAccess(),
            floorplans: [],
            currentFloorplanApprovals: [],
            selectedFloorplan: '',
            showNamingDialog: false,
            showCreationDialog: false,
            showSaveDialog: false,
            showDeleteDialog: false,
            showRequestApprovalDialog: false,
            showApprovalDialog: false,
            showPublishDialog: false,
            loadingSave: false,
            loadingDelete: false,
            loadingRequestApproval: false,
            loadingApprove: false,
            loadingPublish: false,
            alert_nullSelectedFloorplan: false,
            alert_EmptyFloorplanSeats: false,
            alert_floorplanIsPublished: false,
            alert_floorplanIsApproved: false,
            alert_floorplanIsNotApproved: false,
            alert_floorplanApprovalNotRequested: false,
            dialogSettings: {},
        }
    },
    components: {
        ConfirmationDialog,
        TxtInputDialog
    },
    computed: {
        ...mapGetters([
            'currentFloorplan',
            'user',
            'floorplanSeatsList'
        ]),
        selectedChartDisplayName: function () {
            return this.setSeatingChartDisplayName(this.selectedFloorplan);
        },
        userHasApproved: function () {
            var approval = this.currentFloorplanApprovals.find(approval => approval.user.email == this.user.email)
            if(approval == undefined)
                return false
            else if (approval.approvalDate != null)
                return true
            else
                return false
        },
        finalApproverLeft: function () {
            if(this.currentFloorplanApprovals.length == 0)
                return false

            var nullCount = 0;
            this.currentFloorplanApprovals.forEach(app => {
                if (app.approvalDate == undefined)
                    nullCount++;
            })

            if(nullCount == 1)
                return true
            else
                return false;
        },
        userIsApprover: function () {
            var approval = this.currentFloorplanApprovals.find(approval => approval.user.email == this.user.email)
            if(approval != undefined)
                return true;
            else
                return false;
        },
        sortedFloorplanDropdown: function () {
            var fpNew = this.floorplans.filter(fp => fp.stateId == 1)
            var fpApprovalReqested = this.floorplans.filter(fp => fp.stateId == 2)
            var fpApproved = this.floorplans.filter(fp => fp.stateId == 3)
            var fpPublished = this.floorplans.filter(fp => fp.stateId == 4)
            var fpArchived = this.floorplans.filter(fp => fp.stateId == 5)

            const sortedArray = [...fpPublished, ...fpApprovalReqested, ...fpApproved, ...fpNew, ...fpArchived]
            return sortedArray
        }
    },
    methods: {
        ...mapMutations([
            'updateSelectedFloorplan',
        ]),
        openRenameDialog: async function () {
            this.dialogSettings = {
                Title: "Rename Seating Chart",
                MainText: "Set a new name for ",
                InputLabel: "Name",
                SeatingChartName: this.selectedFloorplan.scenarioName,
                ConfirmText: "Rename",
                CancelText: "Cancel",
                ConfirmEvent: "renameChart",
                CancelEvent: "closeDialog"
            };
            this.showNamingDialog = true;
        },
        openCreationDialog: async function () {
            this.dialogSettings = {
                Title: "Create Seating Chart",
                MainText: "Set a name for your new seating chart",
                InputLabel: "Name",
                SeatingChartName: "",
                ConfirmText: "Create",
                CancelText: "Cancel",
                ConfirmEvent: "createChart",
                CancelEvent: "closeDialog"
            };
            this.showCreationDialog = true;
        },
        openSaveDialog: async function () {
            this.dialogSettings = {
                Title: "Save Seating Chart",
                MainText: "Save changes to the following seating chart? Saving will reset any approvals on the floorplan if they exist.",
                SeatingChartName: "- " + this.selectedFloorplan.scenarioName,
                ConfirmText: "Save",
                CancelText: "Cancel",
                ConfirmEvent: "saveChart",
                CancelEvent: "closeDialog"
            };
            this.showSaveDialog = true;
        },
        openDeleteDialog: async function () {
            this.dialogSettings = {
                Title: "Delete Seating Chart",
                MainText: "Are you sure you want to delete the following Seating Chart?",
                SeatingChartName: "- " + this.selectedFloorplan.scenarioName,
                ConfirmText: "Delete",
                CancelText: "Cancel",
                ConfirmEvent: "deleteChart",
                CancelEvent: "closeDialog"
            };
            this.showDeleteDialog = true;
        },
        openRequestApprovalDialog: async function () {
            this.dialogSettings = {
                Title: "Request Approval",
                MainText: "Are you sure you want to request approval for the following Seating Chart? This will send an email to other approvers.",
                SeatingChartName: "- " + this.selectedFloorplan.scenarioName,
                ConfirmText: "Request Approval",
                CancelText: "Cancel",
                ConfirmEvent: "requestApproval",
                CancelEvent: "closeDialog"
            };
            this.showRequestApprovalDialog = true;
        },
        openApprovalDialog: async function () {
            this.dialogSettings = {
                Title: "Approve Seating Chart",
                MainText: "Are you sure you want to approve the following Seating Chart? Any unsaved changes will be lost.",
                SeatingChartName: "- " + this.selectedFloorplan.scenarioName,
                ConfirmText: "Approve",
                CancelText: "Cancel",
                ConfirmEvent: "approveFloorplan",
                CancelEvent: "closeDialog"
            };
            this.showApprovalDialog = true;
        },
        openPublishDialog: async function () {
            this.dialogSettings = {
                Title: "Publish Seating Chart",
                MainText: "Are you sure you want to publish the following Seating Chart?",
                SeatingChartName: "- " + this.selectedFloorplan.scenarioName,
                ConfirmText: "Publish",
                CancelText: "Cancel",
                ConfirmEvent: "publishFloorplan",
                CancelEvent: "closeDialog"
            };
            this.showPublishDialog = true;
        },
        shortenedDate:  function (longDate) {
            var dateToConvert = new Date(longDate);
            var month = dateToConvert.getMonth() + 1;
            var day = dateToConvert.getDate();
            var year = dateToConvert.getFullYear();
            var shortDate = month + "/" + day + "/" + year;
            return shortDate;
        },
        getShortenedUserName: function (userName) {
            var indexAfterLastInitial = userName.indexOf(' ') + 2;
            var firstNameLastInitial = userName.substr(0, indexAfterLastInitial);
            firstNameLastInitial = firstNameLastInitial + '.'
            return firstNameLastInitial; 
        },
        setSeatingChartDisplayName: function (seatingChart) {
            if (seatingChart.stateId == 5) {
                return seatingChart.scenarioName + " (Archived)";
            }
            else if(seatingChart.stateId == 4) {
                return seatingChart.scenarioName + " (Published)";
            }
            else if (seatingChart.stateId == 3) {
                return seatingChart.scenarioName + " (Approved)";
            }
            else if (seatingChart.stateId == 2) {
                return seatingChart.scenarioName + " (Needs Approval)";
            }
            return seatingChart.scenarioName;
        },
        setSelectedFloorplan: async function (value) {
            this.floorplans.forEach(async (element) => {
                if (element.id == value) {
                    this.selectedFloorplan = element;
                    store.commit("updateSelectedFloorplan", this.selectedFloorplan);
                    await this.seatingChartDAO.getSeatAssignments(element.id);
                    this.seatingChartDAO.getFloorplanApprovals(element.id).then(result => this.currentFloorplanApprovals = result.data);
                }
            });
        },
        resetFloorplanDropdown: async function () {
            this.selectedFloorplan = '';
            store.commit("updateSelectedFloorplan", this.selectedFloorplan);
            this.floorplans = [];
            let allFloorplans = await this.seatingChartDAO.getListOfFloorplans();
            allFloorplans.forEach(element => {
                this.floorplans.push(element);
            });
        },
        renameFloorplan: async function (newName) {
            this.showNamingDialog = false;
            this.toggleLoading();
            if (!this.selectedFloorplan)
                this.alert_nullSelectedFloorplan = true;
            else {
                let floorplanToRename = this.selectedFloorplan;
                floorplanToRename.scenarioName = newName;
                floorplanToRename.modifiedDate = new Date().toLocaleString();
                floorplanToRename.modifiedBy = this.getShortenedUserName(this.user.name);
                await this.seatingChartDAO.renameFloorplan(floorplanToRename);
                await this.resetFloorplanDropdown();
                await this.setSelectedFloorplan(floorplanToRename.id);
            }
            this.toggleLoading();
        },
        createFloorplan: async function (newFloorplanName) {
            this.showCreationDialog = false;
            this.toggleLoading();
            let newFloorplan = new Object()
            newFloorplan.scenarioName = newFloorplanName;
            newFloorplan.createdBy = this.getShortenedUserName(this.user.name);
            newFloorplan.createdDate = new Date().toLocaleString();
            newFloorplan.modifiedBy = this.getShortenedUserName(this.user.name);
            newFloorplan.modifiedDate = new Date().toLocaleString();
            newFloorplan.stateId = 1;

            let createdFloorplan = await this.seatingChartDAO.createFloorplan(newFloorplan);
            await this.resetFloorplanDropdown();
            this.setSelectedFloorplan(createdFloorplan.id)
            this.toggleLoading();
        },
        deleteFloorplan: async function () {
            this.showDeleteDialog = false;
            this.loadingDelete = true;
            this.toggleLoading();
            if (!this.selectedFloorplan)
                this.alert_nullSelectedFloorplan = true;
            else if (this.selectedFloorplan.stateId == 4)
                this.alert_floorplanIsPublished = true;
            else {
                await this.seatingChartDAO.deleteLoadedFloorplan(this.selectedFloorplan.id);
                await this.resetFloorplanDropdown();
            }
            this.toggleLoading();
            this.loadingDelete = false;
        },
        saveFloorplan: async function () {
            this.showSaveDialog = false;
            this.loadingSave = true;
            this.toggleLoading();
            if (!this.selectedFloorplan)
                this.alert_nullSelectedFloorplan = true;
            else if (this.floorplanSeatsList.length == 0)
                this.alert_EmptyFloorplanSeats = true;
            else {
                if(this.selectedFloorplan.stateId == 2)
                {
                await this.seatingChartDAO.deleteAllFloorplanApprovals(this.selectedFloorplan.id);
                }

                this.selectedFloorplan.modifiedBy = this.getShortenedUserName(this.user.name);
                this.selectedFloorplan.modifiedDate = new Date().toLocaleString();
                await this.seatingChartDAO.saveLoadedFloorplan(this.selectedFloorplan, this.floorplanSeatsList);
                //await this.resetFloorplanDropdown();
                this.setSelectedFloorplan(this.selectedFloorplan.id);
            }
            this.toggleLoading();
            this.loadingSave = false;
        },
        requestFloorplanApproval: async function () {
            await this.saveFloorplan();
            this.showRequestApprovalDialog = false;
            this.loadingRequestApproval = true;
            this.toggleLoading();
            if (!this.selectedFloorplan) {
                this.alert_nullSelectedFloorplan = true;
            }
            else if (this.selectedFloorplan.stateId == 3) {
                this.alert_floorplanIsApproved = true;
            }
            else if (this.selectedFloorplan.stateId == 4) {
                this.alert_floorplanIsPublished = true;
            }
            else {
                var floorplanToUpdate = this.selectedFloorplan;
                floorplanToUpdate.stateId = 2;
                floorplanToUpdate.approvalRequestedBy = this.getShortenedUserName(this.user.name);
                await this.seatingChartDAO.requestFloorplanApproval(floorplanToUpdate);
                await this.resetFloorplanDropdown();
                await this.setSelectedFloorplan(floorplanToUpdate.id);
            }
            this.toggleLoading();
            this.loadingRequestApproval = false;
        },
        approveSelectedFloorplan: async function () {
            this.showApprovalDialog = false;
            this.loadingApprove = true;
            this.toggleLoading();
            if (!this.selectedFloorplan) {
                this.alert_nullSelectedFloorplan = true;
            }
            else if (this.selectedFloorplan.stateId == 4) {
                this.alert_floorplanIsPublished = true;
            }
            else if (this.selectedFloorplan.stateId == 3) {
                this.alert_floorplanIsApproved = true;
            }
            else if (!this.selectedFloorplan.stateId == 2) {
                this.alert_floorplanApprovalNotRequested = true;
            }
            else {
                var floorplanToApprove = this.selectedFloorplan;
                var currentUser = this.user;
                if(this.finalApproverLeft) //Set floorplan to approved
                {
                    floorplanToApprove.approvalDate = new Date().toLocaleString();
                    floorplanToApprove.stateId = 3;
                    await this.seatingChartDAO.approveFloorplan(floorplanToApprove);
                    console.log("Change of floorplan status to approved activated")
                }
                await this.seatingChartDAO.addFloorplanApproval(floorplanToApprove.id, currentUser.email);

                await this.resetFloorplanDropdown();
                this.setSelectedFloorplan(floorplanToApprove.id);
            }
            this.toggleLoading();
            this.loadingApprove = false;
        },
        unapproveSelectedFloorplan: async function () {
            this.showApprovalDialog = false;
            this.loadingApprove = true;
            this.toggleLoading();
            if (!this.selectedFloorplan) {
                this.alert_nullSelectedFloorplan = true;
            }
            else if (this.selectedFloorplan.stateId == 4) {
                this.alert_floorplanIsPublished = true;
            }
            else if (this.selectedFloorplan.stateId == 3) {
                this.alert_floorplanIsApproved = true;
            }
            else if (!this.selectedFloorplan.stateId == 2) {
                this.alert_floorplanApprovalNotRequested = true;
            }
            else {
                var floorplanToApprove = this.selectedFloorplan;
                var currentUser = this.user;
                await this.seatingChartDAO.deleteFloorplanApproval(floorplanToApprove.id, currentUser.email);
                await this.resetFloorplanDropdown();
                this.setSelectedFloorplan(floorplanToApprove.id);
            }
            this.toggleLoading();
            this.loadingApprove = false;
        },
        publishSelectedFloorplan: async function () {
            this.showPublishDialog = false;
            this.loadingPublish = true;
            this.toggleLoading();
            if (!this.selectedFloorplan) {
                this.alert_nullSelectedFloorplan = true;
            }
            else if (this.selectedFloorplan.stateId == 4) {
                this.alert_floorplanIsPublished = true;
            }
            else if (!this.selectedFloorplan.stateId == 3) {
                this.alert_floorplanIsNotApproved= true;
            }
            else {
                let floorplanToPublish = this.selectedFloorplan;
                floorplanToPublish.publishedDate = new Date().toLocaleString();
                floorplanToPublish.publishedBy = this.getShortenedUserName(this.user.name);
                floorplanToPublish.stateId = 4;
                await this.seatingChartDAO.publishFloorplan(floorplanToPublish);
                await this.resetFloorplanDropdown();
                this.setSelectedFloorplan(floorplanToPublish.id);
            }
            this.toggleLoading();
            this.loadingPublish = false;
        },
        toggleLoading: async function () {
            this.$emit('toggleLoading');
        },
    },
    mounted: async function () {
        this.resetFloorplanDropdown();
    }
}
    
    
</script>

<style>
    .controlBtns {
        
        margin-right:35px;
    }
    .ctrlBtn {
        margin: 10px;
    }

    .historicLabels {
        margin-top: 20px;
        padding-bottom: 20px;
    }
</style>